import React, { useCallback, useEffect, useState } from 'react';
import { WIDGET_TYPE_MAP, WidgetType } from '../constants';
import { IElementPropsSDKActions } from '../../../core/corvid/props-factories';
import { useIFrame } from '../../../providers/useIFrame/useIFrame';

type WTwitterContentProps = {
  id: string;
  title: string;
  widgetType: WidgetType;
  queryString: string;
  width: number;
  height: number;
} & Partial<IElementPropsSDKActions>;

export const WTwitterContent: React.FC<WTwitterContentProps> = props => {
  const {
    id,
    title,
    widgetType,
    queryString,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const iframeSrc = `https://platform.twitter.com/widgets/${WIDGET_TYPE_MAP[widgetType]}.html?${queryString}`;
  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const [render, setRender] = useState(false);

  const handleMessage = useCallback(event => {
    if (event.type !== 'message' || !event.payload) {
      return;
    }
    const buttonObj = event.payload['twttr.button'];

    if (buttonObj && buttonObj.method === 'twttr.private.resizeButton') {
      const size = buttonObj.params[0];
      setWidth(size.width);
      setHeight(size.height);
    }
  }, []);

  const [ref] = useIFrame({ reducer: handleMessage });

  useEffect(() => {
    setRender(true);
  }, []);

  return (
    <div
      id={id}
      style={{ width, height }}
      title={title}
      aria-label={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {render && (
        <wix-iframe data-src={iframeSrc}>
          <iframe
            title={title}
            aria-label={title}
            data-src={iframeSrc}
            scrolling="no"
            ref={ref}
            width={width}
            height={height}
          />
        </wix-iframe>
      )}
    </div>
  );
};

/**
 * NOTE: fix for registry conventions,
 * registry still create entry for this component
 */
export default WTwitterContent;
